import React, { useEffect, useMemo, useState } from 'react';
import { getHours, getMins, getSecs, getMscs } from './utils';
import { useCountdownContext } from './context';

const timeDiff = (finishTimestamp) => {
  const diff = finishTimestamp - Date.now();
  return diff > 0 ? diff : 0;
};

const Countdown = () => {
  var { config, setFinish } = useCountdownContext();
  var finishTimestamp = config.time;

  const initialTimeLeft = useMemo(
    () => timeDiff(finishTimestamp),
    [finishTimestamp]
  );

  const [timeLeft, setTimeLeft] = useState(initialTimeLeft);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTimeLeft = timeDiff(finishTimestamp);
      if (!Boolean(currentTimeLeft)) {
        clearInterval(interval);
        setFinish(true);
      }
      setTimeLeft(currentTimeLeft);
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, [finishTimestamp, setFinish]);

  return (
    <div className="countdown-timer">
      <span>{getHours(timeLeft)}</span> : <span>{getMins(timeLeft)}</span> :{' '}
      <span>{getSecs(timeLeft)}</span> : <span>{getMscs(timeLeft)}</span>
    </div>
  );
};

export default Countdown;
