import React, { useContext } from 'react';
import { useCountdownContext } from './context';

const CountdownText = () => {
  var { config, finish } = useCountdownContext();
  var text = config.text || 'Soon';
  var finishText = config.finishText || 'End';

  return (
    <div className="countdown-text">
      <div>{finish ? finishText : text}</div>
    </div>
  );
};

export default CountdownText;
