export const zeroPad = (value) => {
  if (value < 10) return `0${value}`;
  return `${value}`;
};

export const getHours = (value) => {
  return zeroPad(Math.floor(value / (1000 * 60 * 60 * 24)));
};

export const getMins = (value) => {
  return zeroPad(Math.floor((value / 1000 / 60) % 60));
};

export const getSecs = (value) => {
  return zeroPad(Math.floor((value / 1000) % 60));
};

export const getMscs = (value) => {
  var v = value % 1000;
  if (v < 10) return `00${v}`;
  if (v < 100) return `0${v}`;
  return `${v}`;
};
