import React, { useState, useContext } from 'react';

const CountdownContext = React.createContext();

function CountdownProvider({ children }) {
  const [config, setConfig] = useState();
  const [finish, setFinish] = useState(false);

  const value = { config, setConfig, finish, setFinish };
  return (
    <CountdownContext.Provider value={value}>
      {children}
    </CountdownContext.Provider>
  );
}

function useCountdownContext() {
  const context = useContext(CountdownContext);
  if (context === undefined) {
    throw new Error(
      'useCountdownContext must be used within a CountdownProvider'
    );
  }
  return context;
}

export { CountdownProvider, useCountdownContext };
