import React from 'react';
import Countdown from '~/components/alerts/Countdown/Countdown';
import { CountdownProvider } from './context';
import './index.scss';

const TimeToStart = () => {
  return (
    <CountdownProvider>
      <Countdown />
    </CountdownProvider>
  );
};

export default TimeToStart;
