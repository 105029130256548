import React, { useEffect } from 'react';
import CountdownTimer from '~/components/alerts/Countdown/CountdownTimer';
import CountdownText from '~/components/alerts/Countdown/CountdownText';
import { useCountdownContext } from './context';

/*

curl --location --request POST 'https://api.sfa.gg/hook/nedos/timer' \
--header 'Content-Type: application/json' \
--data-raw '{
    "time": 30000,
    "text": "Starting soon",
    "finishText": "We are getting ready",
    "color": "green",
    "textColor": "white",
    "font": "Gilroy"
}'

*/

const Countdown = () => {
  var { config, setConfig } = useCountdownContext();

  useEffect(() => {
    const interval = 1 /* sec */ * 1000;
    var timer = setTimeout(function fetchHook() {
      fetch(`https://api.sfa.gg/hook/nedos/timer`)
        .then((response) => response.json())
        .then((response) => {
          if (response.webhook) {
            setConfig({
              ...response.webhook,
              time: Date.now() + response.webhook.time,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      timer = setTimeout(fetchHook, interval);
    });
    return () => clearInterval(timer);
  }, [setConfig]);

  return (
    <>
      {config && (
        <div
          key={Date.now()}
          className="countdown"
          style={{
            background: config.color || '',
            color: config.textColor || '',
            fontFamily: config.font || '',
          }}
        >
          <CountdownTimer />
          <CountdownText />
        </div>
      )}
    </>
  );
};

export default Countdown;
